import  Row from 'react-bootstrap/Row';
import styled from "styled-components";


export const FormStyles = styled(Row)`
.form {  
  display: flex;
  justify-content: center;
  font-family: 'Roboto';

  .container {
    padding: 0 35px;
    box-sizing: border-box;
    position: relative;
    max-width: 700px;
    top: 61px;
    background: #fff;
    border: 1px solid #ECECEC;
    border-radius: 3px;
    margin-bottom: 150px;

    h2 {
      padding: 40px 0 20px 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #3D3D3D;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1F1F1F;
    }   

    .hold-button-send {
        display: block;
        text-align: right;
        margin-bottom: 15px;
        position: relative;

        button {
          width: 240px;
          background: #D9D9D9;
          color: #444;
          border: 0;
          outline: 0;
          padding: 10px 20px 10px 20px;
        }
    }

    .group-form {
      padding-bottom: 20px;
      border-radius: 3px;
      background: #dbe6f7;
      margin-bottom: 20px;
      padding: 20px 20px;
      border-bottom: 2px solid transparent;

      label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .form-check {
        padding-left: 35px;
      }   
      
      textarea {
        margin-bottom: 10px;
      }

      .Text-answer-required{
        color: #474747;
        display: block;
        text-align: right;
        font-size: 14px;
        padding-top: 5px;
      }
    }

    .group-form-error{
      border-bottom: 2px solid #ea4f34;
      padding-bottom: 20px;
      border-radius: 3px;
      background: #dbe6f7;
      margin-bottom: 20px;
      padding: 20px 20px;

      label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .form-check {
        padding-left: 35px;
      }

      .Text-answer-required{
        color: #ea4f34;        
        display: block;
        text-align: right;
        font-size: 14px;
      }
    }

    .div-btn {
      display: flex;
      justify-content: end;
      padding-bottom: 20px;

      a {
        text-decoration: none;
        background: #D9D9D9;
        border-radius: 3px;
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
        padding: 10px 16px;
      }
    }   
  }
}

@media screen and (max-width: 990px){
  .form {
    padding: 0px 30px;
    
    margin-bottom: 30px;
    .container {
      top: 30px;
    }
  }
  .hold-button-send {
        button {
          width: 212px !important;
        }
    }
}
`;