import { LoadingStyles } from "./styles";
import React from "react";

function Loading() {
  return (
    <LoadingStyles className="footer">
      <>
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      </>
    </LoadingStyles>
  );
}

export default Loading;
