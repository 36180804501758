import ApiService from "../ApiService";

export interface Evaluation {
  id?:string;
  institutionId: string;
  title: string;
  description: string;
  groupId: string;
  questions: Questions[];
}

export interface Questions {
  id: string;
  evaluationId: string;
  questionType: string;
  description: string;
  ordenation: number;
  answers: Answers[];
  mandatory: boolean;
}
export interface Answers {
  id: string;
  questionId: string;
  description: string;
  ordenation: number;
}

export interface AnswerModel {
  questionId: string;
  answerId: string;
  answerText: string;
  mandatory: boolean;
}
export interface AnswerModelMandatory {
  questionId: string;
}

export default class EvaluationService {
  static async getEvaluations(evaluationId: string | undefined) {
    return ApiService.get<Evaluation>(
      `/api/v1/Evaluation/${evaluationId}/GetById`
    );
  }

  static async postEvaluationAnswer(body: AnswerModel[]) {
    return ApiService.post(`/api/v1/EvaluationAnswer/Insert`, body);
  }

  static async getEvaluationFromAssignment(evaluationId: string | undefined, assingmentId: string | undefined) {
    return ApiService.getNoCatcher<Evaluation>(
      `/api/v1/Evaluation/${evaluationId}/${assingmentId}/GetByAssignmentId`
    );
  }
}
