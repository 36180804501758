/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { FormStyles } from "./styles";
import {
  Form,
  Container,
  FormLabel,
  FormCheck,
  FormControl,
  Button,
} from "react-bootstrap";
import Footer from "../footer/Footer";
import Loading from "../loading/Loading";
import LoadingRequest from "../loadingRequest/LoadingRequest";
import React, { useState, useEffect } from "react";
import EvaluationService, {
  Evaluation,
  AnswerModel,
} from "../../services/evaluation/EvaluationService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

function FormEvaluation() {
  const { evaluationId, assignmentId } = useParams<{
    evaluationId: string | undefined;
    assignmentId: string | undefined;
  }>();
  const [listEvaluations, setListEvaluations] = useState<Evaluation>();
  const [listAnswerForm, setListAnswerForm] = useState<AnswerModel[]>([]);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [expiredForm, setExpiredForm] = useState<boolean>(false);
  const navigate = useNavigate();

  const getEvaluations = async (
    evaluationId: string | undefined,
    assignmentId: string | undefined
  ) => {
    const arryEvaluations = await EvaluationService.getEvaluationFromAssignment(
      evaluationId,
      assignmentId
    );

    if (arryEvaluations === undefined) {
      navigate("/");
    }

    if (arryEvaluations === null) {
      setExpiredForm(true);
    }

    setListEvaluations(arryEvaluations);
    setLoadingPage(false);
  };

  useEffect(() => {
    getEvaluations(evaluationId, assignmentId);
  }, []);

  useEffect(() => {
    if (expiredForm) {
      navigate("/expired-form");
    }
  }, [expiredForm]);

  const handleInputChangeTypeM = (target: any, mandatory: boolean) => {
    if (listEvaluations) {
      let arrayListAnswer = listAnswerForm;
      let currentQuestion = listEvaluations.questions.filter(
        (x) => x.id === target.name
      )[0];

      let elemento = document.getElementById(currentQuestion.id);
      if (elemento) {
        elemento.className = "group-form";
      }

      if (target.checked) {
        let newItem: AnswerModel = {
          questionId: currentQuestion.id,
          answerId: target.id,
          answerText: "",
          mandatory: mandatory,
        };
        arrayListAnswer.push(newItem);
      } else {
        const indexOfObject = arrayListAnswer.findIndex((x) => {
          return x.answerId === target.id;
        });

        arrayListAnswer.splice(indexOfObject, 1);
      }
      setListAnswerForm(arrayListAnswer);
      //setAlterState(!alterState)
    }
  };

  const handleInputChangeTypeD = (target: any, mandatory: boolean) => {
    if (listEvaluations) {
      let arrayListAnswer = listAnswerForm;
      let currentQuestion = listEvaluations.questions.filter(
        (x) => x.id === target.name
      )[0];

      let elemento = document.getElementById(currentQuestion.id);
      if (elemento) {
        elemento.className = "group-form";
      }

      const indexOfObject = arrayListAnswer.findIndex((x) => {
        return x.answerId === target.id;
      });

      if (indexOfObject < 0) {
        let newItem: AnswerModel = {
          questionId: currentQuestion.id,
          answerId: target.id,
          answerText: target.value,
          mandatory: mandatory,
        };
        arrayListAnswer.push(newItem);
      } else {
        let t = ([...arrayListAnswer][indexOfObject].answerText = target.value);
        console.log(t);
      }

      setListAnswerForm(arrayListAnswer);
    }
  };

  const handleInputChangeTypeU = (target: any, mandatory: boolean) => {
    if (listEvaluations) {
      let arrayListAnswer = listAnswerForm;
      let currentQuestion = listEvaluations.questions.filter(
        (x) => x.id === target.name
      )[0];

      let elemento = document.getElementById(currentQuestion.id);
      if (elemento) {
        elemento.className = "group-form";
      }

      const indexOfObject = arrayListAnswer.findIndex((x) => {
        return x.questionId === target.name;
      });
      if (indexOfObject < 0) {
        let newItem: AnswerModel = {
          questionId: currentQuestion.id,
          answerId: target.id,
          answerText: "",
          mandatory: mandatory,
        };
        arrayListAnswer.push(newItem);
      } else {
        arrayListAnswer.splice(indexOfObject, 1);
        let newItem: AnswerModel = {
          questionId: currentQuestion.id,
          answerId: target.id,
          answerText: "",
          mandatory: mandatory,
        };
        arrayListAnswer.push(newItem);
      }
      setListAnswerForm(arrayListAnswer);
    }
  };

  const handleSubmitChange = async (e: any) => {
    e.preventDefault();

    if (listEvaluations) {
      setDisabledButton(true);

      const orderByDesc = listEvaluations.questions.sort((a, b) => {
        if (a.ordenation > b.ordenation) {
          return -1;
        }
        if (a.ordenation < b.ordenation) {
          return 1;
        }
        return 0;
      });

      let getResposta = orderByDesc.map((x) => {
        let filterAnswer = listAnswerForm.filter((y) => y.questionId === x.id);

        if (filterAnswer.length === 0 && x.mandatory) {
          let elemento = document.getElementById(x.id);
          if (elemento) {
            elemento.className = "group-form-error";
            //elemento.scrollIntoView();
            const yOffset = -200;
            const y =
              elemento.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;

            window.scrollTo({ top: y, behavior: "smooth" });
          }
          return false;
        } else {
          let elemento = document.getElementById(x.id);
          if (elemento) {
            elemento.className = "group-form";
          }
          return true;
        }
      });

      let continueSubmit = getResposta.includes(false);

      if (!continueSubmit) {
        setLoadingButton(true);
        const sendArrayEvaluations =
          await EvaluationService.postEvaluationAnswer(listAnswerForm);

        if (sendArrayEvaluations) {
          toast.success("Formulário enviado com sucesso", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          navigate("/thanks");
        }

        setLoadingButton(false);
      } else {
        setDisabledButton(false);
      }
    }
  };

  return (
    <FormStyles>
      <section className="form">
        {loadingPage && (
          <>
            <Loading />
          </>
        )}
        {listEvaluations && (
          <Container>
            <h2>Responda o formulário</h2>
            <p>
              {listEvaluations.title}
              <br />
              {listEvaluations.description}
              {/* <br />
              Turma: 1 Ano A - Matemática.
              <br />
              Professor: Amauri Junior */}
            </p>
            <Form onSubmit={handleSubmitChange}>
              {listEvaluations.questions.map((x) => {
                if (x.questionType === "multiple") {
                  return (
                    <>
                      <div className="group-form" id={x.id}>
                        <FormLabel>{x.description}</FormLabel>
                        {x.answers.map((y) => {
                          return (
                            <FormCheck
                              name={x.id}
                              onChange={(target) =>
                                handleInputChangeTypeM(
                                  target.target,
                                  x.mandatory
                                )
                              }
                              id={y.id}
                              type="checkbox"
                              label={y.description}
                            />
                          );
                        })}
                        {x.mandatory && (
                          <div>
                            <span className="Text-answer-required">
                              *Resposta Obrigatória
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  );
                }
                if (x.questionType === "dissertative") {
                  return (
                    <>
                      <div className="group-form" id={x.id}>
                        <FormLabel>{x.description}</FormLabel>
                        {x.answers.map((y) => {
                          return (
                            <FormControl
                              name={x.id}
                              onChange={(target) =>
                                handleInputChangeTypeD(
                                  target.target,
                                  x.mandatory
                                )
                              }
                              placeholder={y.description}
                              as="textarea"
                              id={y.id}
                              rows={4}
                            />
                          );
                        })}
                        {x.mandatory && (
                          <div>
                            <span className="Text-answer-required">
                              *Resposta Obrigatória
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  );
                }
                if (x.questionType === "unique") {
                  return (
                    <>
                      <div className="group-form" id={x.id}>
                        <FormLabel>{x.description}</FormLabel>
                        {x.answers.map((y) => {
                          return (
                            <FormCheck
                              name={x.id}
                              onChange={(target) =>
                                handleInputChangeTypeU(
                                  target.target,
                                  x.mandatory
                                )
                              }
                              label={y.description}
                              type="radio"
                              id={y.id}
                            />
                          );
                        })}
                        {x.mandatory && (
                          <div>
                            <span className="Text-answer-required">
                              *Resposta Obrigatória
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  );
                }
              })}
              <section className="hold-button-send">
                <Button disabled={disabledButton} type="submit">
                  Finalizar Avaliação
                  {loadingButton && <LoadingRequest />}
                </Button>
              </section>
            </Form>
          </Container>
        )}
      </section>
      <section className="hold-footer">
        <Footer />
      </section>
    </FormStyles>
  );
}

export default FormEvaluation;
