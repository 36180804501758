import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import Environment from "../environment";

const config = {
  headers: {
    Authorization: `Bearer ${Environment.token}`,
  },
};

export interface ApiResult<TResponse> {
  errors: any;
  result: TResponse;
  paging: TResponse;
  success: boolean;
}

export default class ApiService {
  static async get<TResponse>(route: string) {
    try {
      var response = await axios.get<ApiResult<TResponse>>(
        Environment.apiUri + route,
        config
      );
      return response.data.result;
    } catch (error) {
      //@ts-ignore
      catcher(error);
    }
  }

  static async getNoCatcher<TResponse>(route: string) {
    try {
      var response = await axios.get<ApiResult<TResponse>>(
        Environment.apiUri + route,
        config
      );
      return response.data.result;
    } catch (errors) {
      //@ts-ignore
      //catcher(error);
      console.error("Erro => ", errors)
    }
  }

  static async getParams<TResponse>(route: string, params?: string) {
    try {
      var response = await axios.get<ApiResult<TResponse>>(
        Environment.apiUri + route,
        {
          headers: {
            Authorization: `Bearer ${Environment.token}`,
          },
          params: {
            officeId: params,
          },
        }
      );
      return response.data.result;
    } catch (error) {
      //@ts-ignore
      catcher(error);
    }
  }

  static async getPaginated<TResponse>(route: string) {
    try {
      var response = await axios.get<ApiResult<TResponse>>(
        Environment.apiUri + route,
        config
      );
      const { result, paging } = response.data;
      return { result, paging };
    } catch (error) {
      //@ts-ignore
      catcher(error);
    }
  }

  static async patch<TResponse>(route: string, body?: any) {
    try {
      var response = await axios.patch<ApiResult<TResponse>>(
        Environment.apiUri + route,
        body,
        config
      );
      return response.data.result;
    } catch (error) {
      //@ts-ignore
      catcher(error);
    }
  }

  static async post<TResponse>(route: string, body?: any) {
    try {
      var response = await axios.post<ApiResult<TResponse>>(
        Environment.apiUri + route,
        body,
        config
      );
      return response.data.result;
    } catch (error) {
      //@ts-ignore
      catcher(error);
    }
  }

  static async put<TResponse>(route: string, body?: any) {
    try {
      var response = await axios.put<ApiResult<TResponse>>(
        Environment.apiUri + route,
        body,
        config
      );
      return response.data.result;
    } catch (error) {
      //@ts-ignore
      catcher(error);
    }
  }

  static async delete<TResponse>(route: string) {
    try {
      var response = await axios.delete<ApiResult<TResponse>>(
        Environment.apiUri + route,
        config
      );
      return response.data.success;
    } catch (error) {
      //@ts-ignore
      catcher(error);
    }
  }

  static async deleteBody<TResponse>(route: string, body?: any) {
    try {
      var response = await axios.delete(Environment.apiUri + route, {
        headers: {
          Authorization: `Bearer ${Environment.token}`,
        },
        data: body,
      });

      return response.data.success;
    } catch (error) {
      //@ts-ignore
      catcher(error);
    }
  }
}

function catcher(error: AxiosError) {
  // if(error.response?.data === ""){
  //   return window.location.href = '/userdefault'
  // }

  error.response?.data.errors?.forEach((er: any) => {
    if (er.code === "EE004") {
      toast.error(er.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() =>{
        window.location.href = '/'
      },3000)

      return error;
    } 
    else {
      toast.error("Ocorreu um erro, tente novamente!");
      setTimeout(() =>{
        window.location.href = '/'
      },3000)
    }
  });

}
