import { useState } from "react";
import { HomeProps } from "../../domain/props/HomeProps";
import { HomeStyles } from "./styles";
import { NavLink } from "react-router-dom";
import LogoFgv from "../../assets/logo";
import Footer from "../../components/footer/Footer";
import { useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

function Home({}: HomeProps): React.ReactElement {
  const { evaluationId, assignmentId } = useParams<{
    evaluationId: string | undefined;
    assignmentId: string | undefined;
  }>();
  const [verify, setVerify] = useState<boolean>(false);

  const changeRecaptcha = (value: any) => {
    setTimeout(() => {
      if (value) {
        setVerify(true);
      }
    }, 1000);
  };

  return (
    <HomeStyles>
      <>
        {verify ? (
          <section className="home">
            <div className="content-in">
              <LogoFgv />
              <p>
                Seja bem vindo ao portal de avaliações do professor.
                <br />
                As informações preenchidas são totalmente anônimas e nos ajudará
                a melhorar cada vez mais nossos métodos de aprendizados.
              </p>
              <div className="div-btn">
                <NavLink to={`/form/${evaluationId}/${assignmentId}`}>
                  Iniciar Avaliação
                </NavLink>
              </div>
            </div>
          </section>
        ) : (
          <section className="home">
            <div className="content-in-2">
              <ReCAPTCHA
                sitekey="6LcwDmkjAAAAABylP3xHZzobVLjrAEqCSTE-bNrJ"
                onChange={changeRecaptcha}
              />
            </div>
          </section>
        )}
      </>
      <Footer />
    </HomeStyles>
  );
}

export default Home;
