import { LoadingRequestStyles } from "./styles";
import React from "react";

function LoadingRequest() {
  return (
    <LoadingRequestStyles className="footer">
      <>
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      </>
    </LoadingRequestStyles>
  );
}

export default LoadingRequest;
