import  Row from 'react-bootstrap/Row';
import styled from "styled-components";


export const FooterStyles = styled(Row)`
  position: fixed;
  bottom: 0;
  background: #FFF;
  width: 100%;
  min-width: 100%;
  right: 0;
  left:12px;
  -webkit-box-shadow: -1px -1px 7px -1px rgba(0,0,0,0.49);
 -moz-box-shadow: -1px -1px 7px -1px rgba(0,0,0,0.49);
  box-shadow: -1px -1px 7px -1px rgba(0,0,0,0.49);

  .logo-rodape {
    margin-top: 7px;
  }
   .row {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: #0F7EAA;
    }

    .copyright {
      text-align: right;
    }
  }

  @media screen and (max-width: 990px){
    .row {
    display: flex;
    align-items: center;
  }

  .hold-button-send {
    text-align: center !important;

    button {
      margin: auto !important;
      text-align: center;
    }
  }
  .copyright {
      text-align: center !important;
      padding-left: 14px;
      padding-bottom: 10px;
      font-size: 13px;
  }
  .logo-rodape {
    max-width: 80px;
    margin: auto;
    margin-top: 7px;
  }
}  
`;

