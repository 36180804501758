import  Row from 'react-bootstrap/Row';
import styled from "styled-components";

export const ThanksStyles = styled(Row)`
.thanks {
  height: 90vh;
  background: rgb(11,125,209);
  background: radial-gradient(circle, rgba(11,125,209,1) 0%, rgba(0,74,142,1) 56%, rgba(1,36,79,1) 100%);
  position: relative;
  display: flex;
  justify-content: center;

  .content-in {
    background: #FFF;
    padding: 40px;
    width: 600px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 6px;

    p {
      font-weight: 400;
      font-size: 16px;
      margin: 6px;
    }
  }
}

@media screen and (max-width: 990px){
  .thanks {
    .content-in {
      position: relative;
      width: 90%;
      height:200px;
      top: auto;
      left: auto;
      margin-top: auto;
      margin-left: auto;
      margin: 180px auto 0px auto;

      p {
        font-size: 15px;
      }
    }
  }
}
`;
