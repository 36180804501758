import { BrainzAuthContextProvider } from "@brainz_group/webappframework";
import { BrowserRouter } from "react-router-dom";
import BrainzRoutes from "./BrainzRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrainzAuthContextProvider waitAuthentication={false}>
        <BrowserRouter>
          <BrainzRoutes />
        </BrowserRouter>
      </BrainzAuthContextProvider>
    </div>
  );
}
