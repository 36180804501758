import  Row from 'react-bootstrap/Row';
import styled from "styled-components";


export const HomeStyles = styled(Row)`
.home {
  /* height: 90vh;
  background: rgb(11,125,209);
  background: radial-gradient(circle, rgba(11,125,209,1) 0%, rgba(0,74,142,1) 56%, rgba(1,36,79,1) 100%);
  position: relative;
  display: flex; */
  justify-content: center;

  .content-in-2 {
    text-align: center;
    width: 200px;
    height: 200px;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -80px;
    margin-left: -150px;
    border-radius: 6px;
  }

  .content-in {
    text-align: center;
    background: #f8fafb;
    padding: 20px 60px;
    width: 550px;
    height: 300px;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -175px;
    margin-left: -275px;
    border-radius: 6px;

    .text-not-found {
      text-align: center;
      font-weight: 600;
      font-size: 26px;
      color: #333;
    }

    .img-error {
      margin-top: 15px;
    }

    .div-btn {
        display: flex;
        justify-content: center;
        padding-bottom: 40px;
    }

    p {
      text-align: left;
      margin: 20px auto 30px auto;
    }

    a {
      text-decoration: none;
      color: grey;
      background-color: #ddd;
      padding: 5px 50px;
      font-size: 20px;
      font-style: italic;
      font-weight: 100;
      margin-top: 40px;
      border-radius: 4px;
      margin: auto;
    }
  } 
}

@media screen and (max-width:990px){
  
  .home {
    .content-in {
      position: relative;
      width: 90%;
      height:340px;
      top: auto;
      left: auto;
      margin-top: auto;
      margin-left: auto;
      margin: 30px auto 250px auto;
      padding: 20px 30px;

      .text-not-found {
        font-size: 20px;
      }

      p {
        font-size: 15px;
        text-align: center;
      }

      a {
        padding: 8px 20px;
      }
    }
  }
}
`;


