import { HomeProps } from "../../domain/props/HomeProps";
import { HomeStyles } from "./styles";
import Footer from "../../components/footer/Footer";

function HomeEmpty({}: HomeProps): React.ReactElement {
  return (
    <HomeStyles>
      <section className="home">
        <div className="content-in">
          <img
            className="img-error"
            src={require("../../assets/img-user-default.JPG")}
            alt="Erro"
            width="160"
          />
          <p className="text-not-found">Avaliação não encontrada!</p>
        </div>
      </section>
      <Footer />
    </HomeStyles>
  );
}

export default HomeEmpty;
