import { Route, Routes } from "react-router-dom";
import FormEvaluation from "./components/form/FormEvaluation";
import Thanks from "./components/thanks/Thanks";
import Home from "./screens/home/Home";
import HomeEmpty from "./screens/home/HomeEmpty";
import ExpiredForm from "./components/thanks/ExpiredForm";

function BrainzRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomeEmpty />} />
      <Route path="/form" element={<HomeEmpty />} />
      <Route path="/form/:id" element={<HomeEmpty />} />
      <Route path="/:id" element={<HomeEmpty />} />

      <Route path="/:evaluationId/:assignmentId" element={<Home />} />
      <Route
        path="/form/:evaluationId/:assignmentId"
        element={<FormEvaluation />}
      />
      <Route path="/thanks" element={<Thanks />} />
      <Route path="/expired-form" element={<ExpiredForm />} />
    </Routes>
  );
}

export default BrainzRoutes;
