import { createRoot } from 'react-dom/client';
import './appConfigs/index.css';
import './assets/css/styles.css'
import reportWebVitals from './appConfigs/reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

const container = document.getElementById('root');

if(container!==null){
  const root = createRoot(container);
  root.render(<App/>);
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

