import { NavLink } from "react-router-dom";
import { ThanksStyles } from "./styles";
import { Row, Col } from "react-bootstrap";
import Footer from "../footer/Footer";

// import './Footer.scss'

function Thanks() {
  return (
    <ThanksStyles>
      <section className="thanks">
        <div className="content-in">
          <p>
            <strong>Obrigado por preencher esse questionário.</strong>      
          </p>
          <p> A sua avaliação é muito importante para que possamos melhorar.</p>
        </div>
      </section>
      <Footer />
    </ThanksStyles>
  );
}

export default Thanks;
