import { NavLink } from "react-router-dom";
import { FooterStyles } from "./styles";
import { Row, Col } from "react-bootstrap";
import React from "react";

function Footer() {
  const getCurrentYear = () => new Date()?.getFullYear();
  return (
    <FooterStyles>
      <Row>
        <Col md="1" xs="5">
          <NavLink to={"/"}>
            <section className="logo-rodape"></section>
          </NavLink>
        </Col>
        <Col md="8" xs="7">
          <NavLink to={"/"}>Portal Big Brain</NavLink>
          <span className="d-block d-md-none">
            Big Brain Education - {getCurrentYear()} ©{" "}
          </span>
        </Col>
        <Col md="3" xs="12" className="copyright d-none d-md-block">
          <span>Big Brain Education - {getCurrentYear()} © </span>
        </Col>
      </Row>
    </FooterStyles>
  );
}

export default Footer;
