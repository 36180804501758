import { ThanksStyles } from "./styles";
import Footer from "../footer/Footer";

function ExpiredForm() {
  return (
    <ThanksStyles>
      <section className="thanks">
        <div className="content-in">
          <p>
            <strong>
              Este formulário não está disponível para preenchimento.
            </strong>
          </p>
          <p>A data de expiração do formulário foi alcançada.</p>
        </div>
      </section>
      <Footer />
    </ThanksStyles>
  );
}

export default ExpiredForm;
